<template>
  <div class="ny_pad">
    <div class="menu_ico">
      <top_menu></top_menu>
      <!-- ho_menu -->
    </div>
    <!-- 概况 -->
<!--    <div class="gywm_banner container-fluid" id="about1">-->
<!--      <div  v-for="(item, index) in testdata2" :key="index">-->
<!--        <a :href="'/content?id='+item.id"><img src="@/assets/images/icon39.png"/></a><br/>-->
<!--        <span>-->
<!--	  	{{ item.description }}-->
<!--	  </span>-->
<!--      </div>-->
<!--    </div>-->

       <!-- 公司简介 -->
    <div class="gywm_lay1 container-fluid" id="about2">
      <img src="@/assets/images/icon40.png" class="gywm_title"/>
      <div class="container"  v-for="(item, index) in testdata2" :key="index">
        <div class="row">
        <div class="lay_le fl_le col-md-6">
          <img v-if="item.images" :src="item.images" :alt="item.title" class="img">
<!--          <img src="@/assets/images/icon41.png" class="imgr"/><br/>-->
<!--          <span> 辉山乳业的品牌历史可以追溯到1951年，总部坐落于中国辽宁。</span>-->
        </div>
        <!-- lay_le -->
        <div class="lay_ri fl_ri col-md-6">
          <p class="fl_le mar_top0" v-html="item.description">
           </p>
          <p><a :href="'/content?id='+item.id" class="btn btn-warning bmore">查看更多</a></p>
        </div>
      </div>
      </div>

      <!-- gywm_wapper -->
    </div>
    <!-- 领导致辞 -->
<!--    <div class="gywm_lay2" id="about3">-->
<!--      <img src="@/assets/images/icon48.png" class="gywm_title"/>-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-md-6">-->
<!--            <img src="@/assets/images/icon49.png" class="img fl_le"/>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--        <div class="fl_le" v-for="(item, index) in testdata1" :key="index">-->
<!--          <p v-html="fh(item.description)"></p>-->
<!--&lt;!&ndash;          <a :href="'/content?id='+item.id" class="fl_le"><img src="@/assets/images/icon51.png"/></a>&ndash;&gt;-->
<!--          <p><a :href="'/content?id='+item.id" class="btn btn-warning bmore">查看更多</a></p>-->
<!--			</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 公司領導 -->
<!--    <div class="gywm_lay3" id="about4">-->
<!--      <img src="@/assets/images/icon52.png" class="gywm_title"/>-->
<!--      <div class="gywm_wapper">-->
<!--        <ul>-->
<!--          <li v-for="(item,index) in testdata" :key="index">-->
<!--            <a :href="'/content?id='+item.id">-->
<!--              <img :src="item.images" :alt="item.title">-->
<!--            </a>-->
<!--            <span :title="item.keywords">-->
<!--        <a :href="'/content?id='+item.id">{{ (item.title || '') }}</a>-->
<!--      </span></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 业务架构 -->
<!--    <div class="gywm_lay4" id="about5">-->
<!--      <img src="@/assets/images/icon55.png" class="gywm_title"/>-->
<!--      <div class="gywm_wapper clearfix">-->
<!--        <ul>-->
<!--          <li><span>农牧事业部<a href="/chanye#cy1" title="高效  快捷  质量">查看详情 ></a></span><img src="@/assets/images/icon69.png"/></li>-->
<!--          <li><span>农牧事业部<a href="/chanye#cy3" title="高效  快捷  质量">查看详情 ></a></span><img src="@/assets/images/icon70.png"/></li>-->
<!--          <li><span>农牧事业部<a href="/chanye#cy5" title="高效  快捷  质量">查看详情 ></a></span><img src="@/assets/images/icon71.png"/></li>-->
<!--          <li><span>液奶事业部<a href="/ppcp#firstPage" title="高效  快捷  质量">查看详情 ></a></span><img src="@/assets/images/icon72.png"/></li>-->
<!--          <li><span>奶粉事业部<a href="/ppcp#secondPage" title="高效  快捷  质量">查看详情 ></a></span><img src="@/assets/images/icon73.png"/></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 发展战略 -->
    <div class="gywm_lay2 mar_bot80" id="about7">
      <img src="@/assets/images/icon78.png" class="gywm_title"/>

      <div class="container">
        <div class="row" v-for="(item, index) in testdata3" :key="index">
          <div class="col-md-6">
<!--            <img src="@/assets/images/icon76.png" class="img fl_le"/>-->
            <img v-if="item.images" :src="item.images" :alt="item.title" class="img fl_le">
          </div>
          <div class="col-md-6">
            <p class="fl_le mar_top0">
		  {{ item.description }}</p>
            <p><a :href="'/content?id='+item.id" class="btn btn-warning bmore">查看更多</a></p>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业荣誉 -->
    <div class="gywm_lay2 mar_bot80" id="about9">
      <img src="@/assets/images/icon22_.png" class="gywm_title"/>
      <div class="">

          <div class="wrapper">
            <div class="background"> <img src="@/assets/images/pic5.jpg" alt=""> </div>
            <div class="item-bg"></div>
            <div class="news-slider">
              <div class="news-slider__wrp swiper-wrapper">

                <div class="news-slider__item swiper-slide" v-for="(item, index) in rongyu" :key="index">
                  <a :href="'/content?id='+item.id" class="news__item">
                  <div class="news__title"> {{ item.title }}</div>
                  <div class="news__img"> <img :src="item.images" :alt="item.title"> </div>
                </a> </div>



              </div>
              <div class="news-slider__ctr">
                <div class="news-slider__arrows">
                  <button class="news-slider__arrow news-slider-prev"> <span class="icon-font">
        <svg class="icon icon-arrow-left">
          <use xlink:href="#icon-arrow-left"></use>
        </svg>
        </span> </button>
                  <button class="news-slider__arrow news-slider-next"> <span class="icon-font">
        <svg class="icon icon-arrow-right">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
        </span> </button>
                </div>
                <div class="news-slider__pagination"></div>
              </div>
            </div>
          </div>
          <svg hidden="hidden">
            <defs>
              <symbol id="icon-arrow-left" viewBox="0 0 32 32">
                <title>arrow-left</title>
                <path d="M0.704 17.696l9.856 9.856c0.896 0.896 2.432 0.896 3.328 0s0.896-2.432 0-3.328l-5.792-5.856h21.568c1.312 0 2.368-1.056 2.368-2.368s-1.056-2.368-2.368-2.368h-21.568l5.824-5.824c0.896-0.896 0.896-2.432 0-3.328-0.48-0.48-1.088-0.704-1.696-0.704s-1.216 0.224-1.696 0.704l-9.824 9.824c-0.448 0.448-0.704 1.056-0.704 1.696s0.224 1.248 0.704 1.696z"></path>
              </symbol>
              <symbol id="icon-arrow-right" viewBox="0 0 32 32">
                <title>arrow-right</title>
                <path d="M31.296 14.336l-9.888-9.888c-0.896-0.896-2.432-0.896-3.328 0s-0.896 2.432 0 3.328l5.824 5.856h-21.536c-1.312 0-2.368 1.056-2.368 2.368s1.056 2.368 2.368 2.368h21.568l-5.856 5.824c-0.896 0.896-0.896 2.432 0 3.328 0.48 0.48 1.088 0.704 1.696 0.704s1.216-0.224 1.696-0.704l9.824-9.824c0.448-0.448 0.704-1.056 0.704-1.696s-0.224-1.248-0.704-1.664z"></path>
              </symbol>
            </defs>
          </svg>


      </div>
    </div>


    <!-- 发展历程 -->
    <div class="gywm_lay4" id="about6">
      <img src="@/assets/images/icon74.png" class="gywm_title"/>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-timeline">

              <div class="timeline" v-for="(item, index) in licheng" :key="index">
                <div class="timeline-icon"><span class="year">{{ (item.title || '') }}</span></div>
                <div class="timeline-content">
                  <!--                  <h3 class="title"></h3>-->
                  <p class="description">
                    <img v-if="item.images" :src="item.images" :alt="item.title"><br/>
                    {{ item.description }}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <fo_oter></fo_oter>
    <!-- footer -->
  </div>
</template>
<style type="text/css">
.main-timeline{
  overflow: hidden;
  position: relative;
}
.main-timeline .timeline{
  position: relative;
  margin-top: -79px;
}
.main-timeline .timeline:first-child{ margin-top: 0; }
.main-timeline .timeline:before,
.main-timeline .timeline:after{
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline:before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.main-timeline .timeline-icon{
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 25px solid transparent;
  border-top-color: #f44556;
  border-right-color: #f44556;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform: rotate(45deg);
}
.main-timeline .year{
  display: block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.4);
  margin: auto;
  font-size: 30px;
  font-weight: bold;
  color: #f44556;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg);
}
.main-timeline .timeline-content{
  width: 35%;
  float: right;
  background: #f44556;
  padding: 30px 20px;
  margin: 50px 0;
  z-index: 1;
  position: relative;
  text-align: left;
}
.main-timeline .timeline-content:before{
  content: "";
  width: 20%;
  height: 15px;
  background: #f44556;
  position: absolute;
  top: 50%;
  left: -20%;
  z-index: -1;
  transform: translateY(-50%);
}
.main-timeline .title{
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 10px 0;
}
.main-timeline .description{
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin: 0;
}
.main-timeline .timeline:nth-child(2n):before{  }
.main-timeline .timeline:nth-child(2n) .timeline-icon{ transform: rotate(-135deg); }
.main-timeline .timeline:nth-child(2n) .year{ transform: rotate(135deg); }
.main-timeline .timeline:nth-child(2n) .timeline-content{ float: left; }
.main-timeline .timeline:nth-child(2n) .timeline-content:before{
  left: auto;
  right: -20%;
}
.main-timeline .timeline:nth-child(2n) .timeline-icon{
  border-top-color: #e97e2e;
  border-right-color: #e97e2e;
}
.main-timeline .timeline:nth-child(2n) .year{ color: #e97e2e; }
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .timeline-content:before{ background: #e97e2e; }
.main-timeline .timeline:nth-child(3n) .timeline-icon{
  border-top-color: #13afae;
  border-right-color: #13afae;
}
.main-timeline .timeline:nth-child(3n) .year{ color: #13afae; }
.main-timeline .timeline:nth-child(3n) .timeline-content,
.main-timeline .timeline:nth-child(3n) .timeline-content:before{ background: #13afae; }
.main-timeline .timeline:nth-child(4n) .timeline-icon{
  border-top-color: #105572;
  border-right-color: #105572;
}
.main-timeline .timeline:nth-child(4n) .year{ color: #105572; }
.main-timeline .timeline:nth-child(4n) .timeline-content,
.main-timeline .timeline:nth-child(4n) .timeline-content:before{ background: #105572; }
@media only screen and (max-width: 1199px){
  .main-timeline .timeline{ margin-top: -103px; }
  .main-timeline .timeline-content:before{ left: -18%; }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before{ right: -18%; }
}
@media only screen and (max-width: 990px){
  .main-timeline .timeline{ margin-top: -127px; }
  .main-timeline .timeline-content:before{ left: -2%; }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before{ right: -2%; }
}
@media only screen and (max-width: 767px){
  .main-timeline .timeline{
    margin-top: 0;
    overflow: hidden;
  }
  .main-timeline .timeline:before,
  .main-timeline .timeline:nth-child(2n):before{
    box-shadow: none;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(2n) .timeline-icon{
    margin-top: -30px;
    margin-bottom: 20px;
    position: relative;
    transform: rotate(135deg);
  }
  .main-timeline .year,
  .main-timeline .timeline:nth-child(2n) .year{ transform: rotate(-135deg); }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content{
    width: 100%;
    float: none;
    border-radius: 0 0 20px 20px;
    text-align: center;
    padding: 25px 20px;
    margin: 0 auto;
  }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before{
    width: 15px;
    height: 25px;
    position: absolute;
    top: -22px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%,0);
  }
}

/*
swiper
 */
.wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 500px;
}
.wrapper .icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.swiper-wrapper{
  /*min-height: 600px;*/
}
.wrapper .background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, rgba(209, 0, 42, 0.6) 0%, #0E5DC4 100%);
  opacity: 0.9;
}
.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}
.item-bg {
  width: 300px;
  height: 500px;
  position: absolute;
  top: 30px;
  background: #ee720a8c;
  border-radius: 10px;
  box-shadow: 0 6px 26px 6px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: all 0.3s;
  left: -30px;
}
.item-bg.active {
  left: 0;
  top: 0;
  opacity: 1;
}

.news-slider {
  z-index: 2;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1300px) {
  .news-slider {
    max-width: 1000px;
  }
}
@media screen and (max-width: 576px) {
  .news-slider {
    margin-top: 45px;
  }
}
.news-slider__wrp {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
.news-slider__item {
  width: 400px;
  flex-shrink: 0;
}
@media screen and (max-width: 992px) {
  .news-slider__item {
    width: 340px;
  }
}
.news-slider__item.swiper-slide {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.news-slider__item.swiper-slide-active, .news-slider__item.swiper-slide-prev, .news-slider__item.swiper-slide-next {
  opacity: 1;
  pointer-events: auto;
}
.news-slider__ctr {
  margin-top: 30px;
  position: relative;
  z-index: 12;
}
.news-slider__arrow {
  background: #fff;
  border: none;
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 26px 6px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 12;
  cursor: pointer;
  outline: none !important;
}
.news-slider__arrow:focus {
  outline: none !important;
}
.news-slider__arrow .icon-font {
  display: inline-flex;
}
.news-slider__arrow.news-slider-prev {
  left: 15px;
  transform: translateY(-50%);
}
.news-slider__arrow.news-slider-next {
  right: 15px;
  transform: translateY(-50%);
}
.news-slider__pagination {
  text-align: center;
  margin-top: 50px;
}
.news-slider__pagination .swiper-pagination-bullet {
  width: 13px;
  height: 10px;
  display: inline-block;
  background: #fff;
  opacity: 0.2;
  margin: 0 5px;
  border-radius: 20px;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition-delay: 0.5s, 0.5s, 0s;
}
.news-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff;
  width: 100px;
  transition-delay: 0s;
}
@media screen and (max-width: 576px) {
  .news-slider__pagination .swiper-pagination-bullet-active {
    width: 70px;
  }
}
.news__item {
  padding: 40px;
  color: #fff;
  border-radius: 10px;
  display: block;
  transition: all 0.3s;
}
@media screen and (min-width: 800px) {
  .news__item:hover {
    color: #ee720a;
    transition-delay: 0.1s;
  }
  .news__item:hover .news-date, .news__item:hover .news__title, .news__item:hover .news__txt {
    opacity: 1;
    transition-delay: 0.1s;
  }
  .news__item:hover .news__img {
    box-shadow: none;
  }

}
.news__item.active {
  color: #ee720a;
}
.news__item.active .news-date, .news__item.active .news__title, .news__item.active .news__txt {
  opacity: 1;
}
.news__item.active .news__img {
  box-shadow: none;
}
@media screen and (max-width: 992px) {
  .news__item {
    padding: 30px;
  }
  .news-slider__ctr {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .news__item {
    padding: 20px;
  }
}
.news-date {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid;
  display: inline-block;
  opacity: 0.7;
  transition: opacity 0.3s;
}
@media screen and (max-width: 576px) {
  .news-date {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    padding-bottom: 0;
  }
}
.news-date__title {
  display: block;
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 500;
}
@media screen and (max-width: 576px) {
  .news-date__title {
    margin-right: 10px;
  }
}
.news-date__txt {
  font-size: 16px;
}
.news__title {
  font-size: 25px;
  font-weight: 500;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 576px) {
  .news__title {
    font-size: 22px;
    margin-bottom: 10px;
  }
}
.news__txt {
  margin: 10px 0;
  line-height: 1.6em;
  font-size: 15px;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.news__img {
  border-radius: 10px;
  box-shadow: 0 6px 26px 6px rgba(0, 0, 0, 0.25);
  height: 200px;
  margin-top: 30px;
  width: 100%;
  transition: all 0.3s;
  transform-origin: 0% 0%;
}
@media screen and (max-width: 576px) {
  .news__img {
    height: 180px;
    margin-top: 20px;
  }
}
.news__img img {
  max-width: 100%;
  border-radius: 10px;
  /*height: 100%;*/
  width: 100%;
}
.gywm_lay1 .lay_ri li {
  margin-bottom: -18px;
}
</style>
<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import {GETDATA1, GETDATA2, GETDATA3, GETDATA4, GETDATA5} from '../apis/about.js'
import $ from "jquery/dist/jquery.min.js";
window.$ = $;
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper.min.css';
import {GETLISTDATA} from "@/apis/api";

export default {
  name: 'about',
  components: {
    top_menu, fo_oter
  },
  data() {
    return {
      testdata: [],
      testdata1: [],
      testdata2: [],
      testdata3: [],
      licheng:[],
      rongyu:[],
    };
  },
  mounted() {
    this.onloading();
    let url = window.location.href;
    if(url.indexOf("#") > 0){
      let id = url.substring( url.lastIndexOf("#")+1);
      if(id){
        document.getElementById(id).scrollIntoView(true);//$("html body").animate({scrollTop: id }, 1000);
      }
    }

    // var bg = document.querySelector('.item-bg');
    // var items = document.querySelectorAll('.news__item');
    // var item = document.querySelector('.news__item');
    //
    // if($(window).width() > 800) {
    //   $(document).on("mouseover", ".news__item", function (_event, _element) {
    //
    //     var newsItem = document.querySelectorAll('.news__item');
    //     newsItem.forEach(function (element, index) {
    //       element.addEventListener('mouseover', function () {
    //         var x = this.getBoundingClientRect().left;
    //         var y = this.getBoundingClientRect().top - 120;
    //         var width = this.getBoundingClientRect().width;
    //         var height = this.getBoundingClientRect().height;
    //
    //         $('.item-bg').addClass('active');
    //         $('.news__item').removeClass('active');
    //         // $('.news__item').removeClass('active');
    //
    //
    //         bg.style.width = width-10 + 'px';
    //         bg.style.height = height + 'px';
    //         bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
    //       });
    //
    //       element.addEventListener('mouseleave', function () {
    //         $('.item-bg').removeClass('active');
    //         $('.news__item').removeClass('active');
    //       });
    //
    //     });
    //
    //   });
    // }
    GETLISTDATA({catid:16,pageNum:1,pageSize:10}).then((res) => {
      if(res.code == 200){
        this.rongyu = res.rows;
        let geshu = 3;
        if($(window).width() < 768) {
          geshu = 1;
        }
        var swiper = new Swiper('.news-slider', {
          effect: 'coverflow',
          grabCursor: true,
          loop: true,
          initialSlide: 1,
          centeredSlides: true,
          keyboard: true,
          spaceBetween: 0,
          slidesPerView: geshu,
          speed: 300,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 3,
            slideShadows: false
          },
          breakpoints: {
            480: {
              spaceBetween: 0,
              centeredSlides: true
            }
          },
          simulateTouch: true,
          navigation: {
            nextEl: '.news-slider-next',
            prevEl: '.news-slider-prev'
          },
          pagination: {
            el: '.news-slider__pagination',
            clickable: true
          },
          on: {
            init: function () {
              // var activeItem = document.querySelector('.swiper-slide-active');
              //
              // var sliderItem = activeItem.querySelector('.news__item');
              //
              // $('.swiper-slide-active .news__item').addClass('active');
              //
              // var x = sliderItem.getBoundingClientRect().left;
              // var y = sliderItem.getBoundingClientRect().top;
              // var width = sliderItem.getBoundingClientRect().width;
              // var height = sliderItem.getBoundingClientRect().height;
              //
              //
              // $('.item-bg').addClass('active');
              //
              // bg.style.width = width + 'px';
              // bg.style.height = height + 'px';
              // bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
            }
          }
        });

        swiper.on('touchEnd', function () {
          $('.news__item').removeClass('active');
          $('.swiper-slide-active .news__item').addClass('active');
        });

        swiper.on('slideChange', function () {
          $('.news__item').removeClass('active');
        });

        swiper.on('slideChangeTransitionEnd', function () {
          // $('.news__item').removeClass('active');
          // var activeItem = document.querySelector('.swiper-slide-active');
          //
          // var sliderItem = activeItem.querySelector('.news__item');
          //
          // $('.swiper-slide-active .news__item').addClass('active');
          //
          // var x = sliderItem.getBoundingClientRect().left;
          // var y = sliderItem.getBoundingClientRect().top;
          // var width = sliderItem.getBoundingClientRect().width;
          // var height = sliderItem.getBoundingClientRect().height;
          //
          //
          // $('.item-bg').addClass('active');
          //
          // bg.style.width = width + 'px';
          // bg.style.height = height + 'px';
          // bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
        });


      }
    });



  },
  methods: {
    async onloading() {
      this.testdata = await GETDATA1();
      this.testdata1 = await GETDATA2();
      this.testdata2 = await GETDATA3();
      this.testdata3 = await GETDATA4();
      this.testdata = this.testdata.rows;
      this.testdata1 = this.testdata1.rows;
      this.testdata2 = this.testdata2.rows;
      this.testdata3 = this.testdata3.rows;
      GETDATA5(17).then((res) => {
        if(res.code == 200){
          this.licheng = res.rows;
        }
      });
    },
  fh(str){
    let rst='';
    if (str){
        rst = str.replace(/\n/g,"<br>");
      }
    return rst;
  },
  }
}
</script>