//home.js 用于获取首页json数据

import req from './http.js'  //引入封装好的axios

//在这里定义了一个登陆的接口，把登陆的接口暴露出去给组件使用
//公司领导
export const GETDATA1 =params=>req('get','cms/article/list/?catid=13&pageNum=1&pageSize=3'+'&orderColumn=sort desc,publish_time desc',params)
//领导致辞
export const GETDATA2 =params=>req('get','cms/article/list/?catid=12&pageNum=1&pageSize=1',params)
//公司简介
export const GETDATA3 =params=>req('get','cms/article/list/?catid=11&pageNum=1&pageSize=1',params)
//发展战略
export const GETDATA4 =params=>req('get','cms/article/list/?catid=15&pageNum=1&pageSize=1',params)
//发展历程
export const GETDATA5 =params=>req('get','cms/article/list/?catid=17&orderByColumn=title&isAsc=desc',params)
